/**
 * Playback modes
 *
 * - `FULL_VIDEO_MODE` means that video play one video file from the very beginning to the end
 * - `PLAYLIST_MODE` means that video play number of short segments of different videos
 *
 * @type {{FULL_VIDEO_MODE: string, PLAYLIST_MODE: string}}
 */
export const playModes = {
    PLAYLIST_MODE: 'PLAYLIST_MODE',

    FULL_VIDEO_MODE: 'FULL_VIDEO_MODE'
};

/**
 * Playback states
 *
 * - `PLAYING` means that video is playing
 * - `PAUSED` means that video is paused
 * - `STOPPED` means that video is stopped
 *
 * @type {{PAUSED: string, PLAYING: string, STOPPED: string}}
 */
export const playStates = {
    PLAYING: 'PLAYING',

    PAUSED: 'PAUSED',

    STOPPED: 'STOPPED',

    SKETCH: 'SKETCH'
};

/**
 * Types of rewind controls
 *
 * @type {{BACKWARD: string, FORWARD: string}}
 */
export const rewindControls = {
    FORWARD: 'FORWARD',

    BACKWARD: 'BACKWARD'
};

/**
 * Types of video speed
 *
 * @type {{SLOW3: string, SLOW2: string, SLOW1: string, ORIGINAL: string, FAST1:string, FAST2:string, FAST3:string, FAST4:string }}
 */
export const speedRates = {
    SLOW3: 'SLOW3',
    SLOW2: 'SLOW2',
    SLOW1: 'SLOW1',
    ORIGINAL: 'ORIGINAL',
    FAST1: 'FAST1',
    FAST2: 'FAST2',
    FAST3: 'FAST3',
    FAST4: 'FAST4',
    FAST5: 'FAST5'
};

export const speedRatesValue = {
    SLOW3: 0.25,
    SLOW2: 0.5,
    SLOW1: 0.75,
    ORIGINAL: 1,
    FAST1: 1.25,
    FAST2: 1.5,
    FAST3: 1.75,
    FAST4: 2,
    FAST5: 4
};

export const deltaVideoDurationValues = [1, 3, 5, 7, 10, 15, 20, 25, 30];

export const videoPlayerPlaylistTabs = {
    ALL: 'ALL',
    PLAYLISTS: 'PLAYLISTS',
    DRAW: 'DRAW',
    TRACK_DETAILS: 'TRACK_DETAILS'
};

export const DEFAULT_VIDEO_WIDTH = 1280;
export const DEFAULT_VIDEO_HEIGHT = 720;

export const ratioWidthToHeight = DEFAULT_VIDEO_WIDTH / DEFAULT_VIDEO_HEIGHT;

export const playlistMinWidth = 250;
export const playlistMinHeight = 200;

export const MAX_EPISODES_FOR_LOADING_COUNT = 100;
export const containerRowHeight = '2rem';
export const rowBorderRadius = '2.5px';
export const boxShadow =
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)';

export const SIZE_DEFAULT = 420;

export const zIndexesVideoPlayer = {
    playlist: 13,
    frameTimeLine: 14,
    videoControls: 14,
    addToPlaylist: 15
};

export const videoPlayerWithPlaylistBlockZIndex = 1001;
export const videoPlayerArchiveZIndex = 1000;
export const videoPlayerSafariPlayZIndex = 15;
export const videoPlayerComponentWrapperZIndex = 1000;
export const videoPlayerSuperTop = 3000;
export const fullScreenDialogWrapperZIndex = 1200;

export const UNITS_CONVERT = {
    SECONDS_TO_MILISECONDS: 1000,
    DECISECONDS_TO_MILISECONDS: 100
};

export const TIME_GAPS = {
    BEFORE: {
        SEC: 7,
        DSEC: 50
    },
    AFTER: {
        SEC: 5,
        DSEC: 30
    }
};
export const convertDSecToSec = 1 / 10;

export const SPECIAL_TIME_BEFORE_S = 10;
export const SPECIAL_TIME_AFTER_S = 30;
export const VIP_CLIENT_IDS = [3233, 3216, 3192, 3195, 3241, 3238, 3220, 3289];
