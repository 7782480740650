import React from 'react';
import { transformTime } from '../../utils/helpers/date-utils';
import { connect } from 'react-redux';
import FormattedMessage from '../formatted-message/index';
import { createSelector } from 'reselect';
import { hasTemporaryKey, STORAGE_METRIC_UNITS } from '../../utils/api/auth-utils';
/**
 * @module
 * @todo refactor this bad-designed module
 * @description next version API proposal:
 *  system: METRIC , IMPERIAL
 * metrics: none, distance, long-distance, time,  speed, acceleration, weight, height,
 * unit: todo: spicify
 * format: d:d:d, d%, d.d%, d.dd%, d, d.d, d.dd, d'd, d'd'd,
 * formatValue({system: [[injected]], metric: string, unit: string, value: number, format: string. withMetric: boolean = false, fallback: string = '_' })
 *
 */

const isNumber = value => typeof value === 'number' && isFinite(value);

const isInteger = value => parseInt(value) === value;

const NULL = '_';
export const UNDEFINED_VALUE = NULL;

const transformNumber = value => (isInteger(value) ? value : isNumber(value) ? value.toFixed(1) : NULL);

export const transformRealNumber = (value, n) => (isNumber(value) ? value.toFixed(n) : NULL);

const transformInt = value => transformRealNumber(value, 0);

export const or = (a, b) => (isNumber(a) ? a : b);
export const strictDivision = (a, b) => {
    return isNumber(a) && isNumber(b) ? a / b : NaN;
};
export const strictSum = (a, b) => (isNumber(a) && isNumber(b) ? a + b : NaN);
export const strictSumArray = a => (a.length ? a.reduce((a, b) => strictSum(a, b)) : NaN);
export const strictRelation = (a, b) => strictDivision(a, strictSum(a, b));
export const strictMul = (a, b) => (isNumber(a) && isNumber(b) ? a * b : NaN);
export const numberComparator = d => (isNumber(d) ? d : -Infinity);

function transformShortDistance(dist) {
    if (!isNumber(dist)) return NULL;
    return `${transformInt(dist / 12)}'${transformInt(dist % 12)}''`;
}

const SM = 1;
const M = 100 * SM;
const KM = 1000 * M;
const MILE = 160934.4 * SM; //https://ru.wikipedia.org/wiki/%D0%9C%D0%B8%D0%BB%D1%8F

const DS = 1;
const MS = DS / 100;
const SEC = 10 * DS;
const MIN = 60 * SEC;
const H = 60 * MIN;
const sm = 'sm';
const m = 'm';
const ft = 'ft';
const inch = 'inch';
const km = 'km';
const mile = 'mile';
const smPerSec = 'smPerSec';
const mPerSec = 'mPerSec';
const kmPerH = 'kmPerH';
const mph = 'mph';
const smPerSec2 = 'smPerSec2';
const mPerSec2 = 'mPerSec2';
const plural = 'plural';
const unit = 'unit';
const hundredths = 'hundredths';
const thousandths = 'thousandths';
const percentage = 'percentage';
const ms = 'ms';
const ds = 'ds';
const sec = 'sec';
const min = 'min';
const h = 'h';
export const SHORT_DISTANCE_MEASURES = {
    sm,
    m,
    ft
};
export const LONG_DISTANCE_MEASURES = {
    km,
    mile
};
export const TIME_MEASURES = {
    ms,
    ds,
    sec,
    min,
    h
};
export const SPEED_MEASURES = {
    smPerSec,
    mPerSec,
    kmPerH,
    mph
};
export const ACCELERATION_MEASURES = {
    smPerSec2,
    mPerSec2
};
export const QUANTITY_MEASURES = {
    plural
};
export const AMOUNT_MEASURES = {
    unit,
    hundredths,
    thousandths,
    percentage
};
export const UNITS = {
    ...SHORT_DISTANCE_MEASURES,
    ...LONG_DISTANCE_MEASURES,
    ...TIME_MEASURES,
    ...SPEED_MEASURES,
    ...ACCELERATION_MEASURES,
    ...QUANTITY_MEASURES,
    ...AMOUNT_MEASURES
};
export const RELATIVE_DURATIONS = {
    default: undefined,
    per20: 20 * 60 * 10,
    per2: 2 * 60 * 10
};
const MEASURE_SCALES = {
    SHORT_DISTANCE: {
        [sm]: 1,
        [inch]: 1 / 2.54,
        [ft]: 1 / 30.48,
        [m]: SM / M
    },
    LONG_DISTANCE: {
        [km]: SM / KM,
        [mile]: SM / MILE
    },
    SPEED: {
        [smPerSec]: 1,
        [mPerSec]: SM / M,
        [kmPerH]: (SM / KM) * (H / SEC),
        [mph]: (SM / MILE) * (H / SEC)
    },
    ACCELERATION: {
        [smPerSec2]: 1,
        [mPerSec2]: SM / M
    },
    QUANTITY: {
        [plural]: 1
    },
    AMOUNT: {
        [unit]: 1,
        [hundredths]: 0.01,
        [thousandths]: 0.001,
        [percentage]: 100
    },
    TIME: {
        [ms]: MS / DS,
        [ds]: 1,
        [sec]: SEC,
        [min]: MIN,
        [h]: H
    }
};
const METRIC_UNITS = {
    SHORT_DISTANCE: MEASURE_SCALES.SHORT_DISTANCE[m],
    LONG_DISTANCE: MEASURE_SCALES.LONG_DISTANCE[km],
    SPEED: MEASURE_SCALES.SPEED[kmPerH],
    ACCELERATION: MEASURE_SCALES.ACCELERATION[mPerSec2],
    QUANTITY: MEASURE_SCALES.QUANTITY[plural],
    AMOUNT: MEASURE_SCALES.AMOUNT[unit],
    TIME: MEASURE_SCALES.TIME[ds]
};
const METRIC_UNITS_TITLES = {
    SHORT_DISTANCE: m,
    LONG_DISTANCE: km,
    SPEED: kmPerH,
    ACCELERATION: mPerSec2,
    QUANTITY: plural,
    AMOUNT: unit,
    TIME: ds
};
const IMPERIAL_UNITS = {
    SHORT_DISTANCE: MEASURE_SCALES.SHORT_DISTANCE[ft],
    LONG_DISTANCE: MEASURE_SCALES.LONG_DISTANCE[mile],
    SPEED: MEASURE_SCALES.SPEED[mph],
    ACCELERATION: MEASURE_SCALES.ACCELERATION[mPerSec2],
    QUANTITY: MEASURE_SCALES.QUANTITY[plural],
    AMOUNT: MEASURE_SCALES.AMOUNT[unit],
    TIME: MEASURE_SCALES.TIME[ds]
};
const IMPERIAL_UNITS_TITLES = {
    SHORT_DISTANCE: ft,
    LONG_DISTANCE: mile,
    SPEED: mph,
    ACCELERATION: mPerSec2,
    QUANTITY: plural,
    AMOUNT: unit,
    TIME: ds
};
const DEFAULT_MEASURE = METRIC_UNITS;
export const NUMBER_FORMATS = {
    time: t => transformTime(t),
    timeZ: t => transformTime(t, { pattern: 'mm:ss.z' }),
    nhlShortDistance: d => transformShortDistance(d),
    number: n => transformNumber(n),
    float1: d => transformRealNumber(d, 1),
    float2: d => transformRealNumber(d, 2),
    int: d => transformInt(d)
};

export const getMetric = () => {
    return sessionStorage.getItem(STORAGE_METRIC_UNITS) || localStorage.getItem(STORAGE_METRIC_UNITS) || 'METRIC';
};

export const getMeasureData = () => {
    const metricUnits = getMetric();
    return metricUnits === 'METRIC' ? METRIC_UNITS : IMPERIAL_UNITS;
};

export const saveMetric = measure => {
    if (hasTemporaryKey()) {
        sessionStorage.setItem(STORAGE_METRIC_UNITS, measure);
    } else {
        localStorage.setItem(STORAGE_METRIC_UNITS, measure);
    }
};
export const NumberFormatter = ({ value, format, scale = 1, className }) => {
    const formattedValue = format(strictMul(scale, value));
    return className ? <span className={className}>{formattedValue}</span> : formattedValue;
};
const unitsDataSelector = createSelector(
    portalUnits => portalUnits,
    units => {
        return { ...units, data: getMeasureData() };
    }
);
export const connectUnits = connect(state => unitsDataSelector(state.portalUnits));
export const TimeFormatter = ({ value, scale, className, timeFormat }) => (
    <NumberFormatter
        value={value}
        format={!!timeFormat ? timeFormat : NUMBER_FORMATS.time}
        scale={scale}
        className={className}
    />
);
export const timeFormat = ({ value, scale = 1 }) => NUMBER_FORMATS.time(strictMul(scale, value));
export const QuantityFormatter = ({ value, scale, className }) => (
    <NumberFormatter value={value} format={NUMBER_FORMATS.number} scale={scale} className={className} />
);

export const ShortFormatter = connectUnits(({ value, measure, format, scale, className, data }) => (
    <NumberFormatter
        value={strictMul(MEASURE_SCALES.SHORT_DISTANCE[measure] || data.SHORT_DISTANCE, value)}
        format={format || NUMBER_FORMATS.float1}
        scale={scale}
        className={className}
    />
));
export const shortFormat = ({ value, scale = 1, measure, data, format = NUMBER_FORMATS.float1 }) =>
    format(strictMul(scale, strictMul(MEASURE_SCALES.SHORT_DISTANCE[measure] || data.SHORT_DISTANCE, value)));
export const getShortFormat = createSelector(
    unitsDataSelector,
    ({ data }) => props => shortFormat({ ...props, data })
);
export const LongDistanceFormatter = connectUnits(({ value, measure, format, scale, className, data }) => (
    <NumberFormatter
        value={strictMul(MEASURE_SCALES.LONG_DISTANCE[measure] || data.LONG_DISTANCE, value)}
        format={format || NUMBER_FORMATS.float1}
        scale={scale}
        className={className}
    />
));
export const longDistanceFormat = ({ value, scale = 1, measure, data, format = NUMBER_FORMATS.float1 }) =>
    format(strictMul(scale, strictMul(MEASURE_SCALES.LONG_DISTANCE[measure] || data.LONG_DISTANCE, value)));
export const getLongDistanceFormat = createSelector(
    unitsDataSelector,
    ({ data }) => props => longDistanceFormat({ ...props, data })
);
export const SpeedFormatter = connectUnits(({ value, measure, format, scale, className, data }) => (
    <NumberFormatter
        value={strictMul(MEASURE_SCALES.SPEED[measure] || data.SPEED, value)}
        format={format || NUMBER_FORMATS.float1}
        scale={scale}
        className={className}
    />
));
export const speedFormat = ({ value, scale = 1, measure, data, format = NUMBER_FORMATS.float1 }) =>
    format(strictMul(scale, strictMul(MEASURE_SCALES.SPEED[measure] || data.SPEED, value)));
export const getSpeedFormat = createSelector(
    unitsDataSelector,
    ({ data }) => props => speedFormat({ ...props, data })
);
export const AccelerationFormatter = ({ value, measure, format, scale, className }) => (
    <NumberFormatter
        value={strictMul(MEASURE_SCALES.ACCELERATION[measure] || DEFAULT_MEASURE.ACCELERATION, value)}
        format={format || NUMBER_FORMATS.float2}
        scale={scale}
        className={className}
    />
);
export const accelerationFormat = ({ value, scale = 1, measure, data, format = NUMBER_FORMATS.float2 }) =>
    format(strictMul(scale, strictMul(MEASURE_SCALES.ACCELERATION[measure] || DEFAULT_MEASURE.ACCELERATION, value)));
export const AmountFormatter = ({ value, measure, format, scale, className }) => (
    <NumberFormatter
        value={strictMul(MEASURE_SCALES.AMOUNT[measure] || DEFAULT_MEASURE.AMOUNT, value)}
        format={format || NUMBER_FORMATS.number}
        scale={scale}
        className={className}
    />
);
export const amountFormat = ({ value, scale = 1, measure, data, format = NUMBER_FORMATS.number }) =>
    format(strictMul(scale, strictMul(MEASURE_SCALES.AMOUNT[measure] || data.AMOUNT, value)));
export const FracFormatter = ({ dividend, divider, format, measure, scale, className }) => {
    const value = strictDivision(dividend, divider);
    return (
        <NumberFormatter
            value={strictMul(value, MEASURE_SCALES.AMOUNT[measure] || DEFAULT_MEASURE.AMOUNT)}
            format={format}
            scale={scale}
            className={className}
        />
    );
};
export const fracFormat = ({ dividend, divider, scale = 1, measure, format = NUMBER_FORMATS.number }) =>
    format(
        strictMul(
            scale,
            strictMul(MEASURE_SCALES.AMOUNT[measure] || DEFAULT_MEASURE.AMOUNT, strictDivision(dividend, divider))
        )
    );
export const SumFormatter = ({ values, format, measure, scale, className }) => {
    const value = strictSumArray(values);
    return (
        <NumberFormatter
            value={strictMul(value, MEASURE_SCALES.AMOUNT[measure] || DEFAULT_MEASURE.AMOUNT)}
            format={format}
            scale={scale}
            className={className}
        />
    );
};
export const RelativeFormatter = ({ toi, duration, children }) => {
    if (!children) return null;
    return (
        <span>
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    scale: or(strictDivision(duration, toi), 1)
                })
            )}{' '}
        </span>
    );
};
export const rel = (toi, duration, value) => strictMul(value, or(strictDivision(duration, toi), 1));
/**
 * @todo move to components
 */

export const UnitTitle = connectUnits(({ type, measure }) => {
    switch (type) {
        case 'METRIC':
            return <FormattedMessage id={`measures.${METRIC_UNITS_TITLES[measure]}`} />;

        case 'IMPERIAL':
            return <FormattedMessage id={`measures.${IMPERIAL_UNITS_TITLES[measure]}`} />;

        default:
    }
});
