import styled from 'styled-components/macro';
import { desktopMediaCondition, mobileMediaCondition } from '../../utils/helpers/device-utils';

const controlColor = 'var(--uiPrimaryColor)';
const fontColor = 'var(--backgroundColorSecondary)';
const buttonBackgroundLightColor = `var(--uiPrimaryColorActive)`;
const activeControlColor = 'rgba(19,53,144,0.72)';

const width = 25;

const blockWidth = `${width}rem`;

export const DateRangeFieldWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: ${blockWidth};

    @media ${mobileMediaCondition} {
        width: 100%;
    }

    @media ${desktopMediaCondition} {
        width: 200px;
    }
`;

export const DateRangeFieldControls = styled('div')`
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 10px 1fr auto auto;

    width: inherit;
    height: 24px;
    @media ${mobileMediaCondition} {
        flex-direction: column;
    }
`;

export const DateRangeFieldControl = styled('div')`
    display: inline-block;

    grid-row: 1;
    margin-top: 1px;
    padding: 6px 0.25rem;

    border: none;
    border-radius: 4px;

    color: ${fontColor};
    font-weight: 600;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;

    background: ${props => (props.isActive ? `${activeControlColor}` : `${controlColor}`)};
    outline: none;

    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;

    &:hover {
        background: ${buttonBackgroundLightColor};

        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
`;

export const DateRangeFieldControlBegin = styled(DateRangeFieldControl)`
    grid-column: 1;
`;

export const DateRangeFieldControlEnd = styled(DateRangeFieldControl)`
    grid-column: 3;
`;

export const DateRangeFieldControlResetWrapper = styled('div')`
    display: flex;
    flex-direction: column;

    grid-column: 5;
    grid-row: 1;
    justify-content: center;
    align-items: center;

    width: 1.5rem;
    height: 1.5rem;
    padding: 2px;
`;

export const DateRangeFieldControlReset = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;

    border-radius: 0.5rem;

    color: ${fontColor};
    font-weight: bolder;
    font-size: 0.7rem;
    text-decoration: none;

    background: ${controlColor};
    outline: none;
    cursor: pointer;

    user-select: none;

    &:hover {
        background: ${buttonBackgroundLightColor};
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
`;

export const DaysPickerWrapper = styled('div')`
    position: absolute;

    top: 1.75rem;

    display: grid;

    width: 282px;
    padding: ${props => (props.leftPadding ? `0 0 0 ${props.leftPadding}` : '5px 5px 5px 2rem')};

    border-radius: 5px;
`;

const DayPickerWrapper = styled('div')`
    border-radius: 1px;

    color: var(--uiSecondaryColor);

    background: var(--backgroundColorSecondary);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
`;

export const DayPickerWrapperBegin = styled(DayPickerWrapper)`
    grid-column-end: 20;
    grid-column-start: 2;
`;

export const DayPickerWrapperEnd = styled(DayPickerWrapper)`
    grid-column-end: 33;
    grid-column-start: 14;
`;

export const StyledDateRangeWrapper = styled.div`
    position: relative;
    z-index: 2000;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media ${mobileMediaCondition} {
        width: 100%;
    }
`;
