import React, { Fragment } from 'react';
import { fieldTypes } from '../../../components/crud-table/field-types';
import isFunction from 'lodash/isFunction';
import {
    StyledCheckBox,
    StyledField,
    StyledFilterSelect,
    StyledInputDateTime,
    StyledInputTime,
    StyledLabel,
    StyledReactSelect,
    StyledTextArea
} from './styled-fields';
import DateRangeField from '../../../components/date-range-picker/date-range-field';
import { createSelector } from 'reselect';
import { StyledDateRangeWrapper } from '../../../components/date-range-picker/date-range-field-styled-components';

const defaultSingleValuesPresentation = fieldName => ([value]) => value || fieldName;
const defaultMultipleValuesPresentation = fieldName => values => `${values.length} ${fieldName}`;
const defaultOptionPresentation = option => String(option);

const getOptionFilterPredicate = createSelector(
    optionPresentation => optionPresentation,
    presentation => (option, find = '') =>
        presentation(option)
            .toLowerCase()
            .includes(find.toLowerCase().trim())
);

const Field = ({
    type,
    fieldName,
    label,
    value,
    onChange,
    path,
    valuesPresentation,
    optionPresentation,
    optionFilterPredicate,
    creatable,
    single,
    hideSeconds = true,
    labeled,
    ...rest
}) => {
    const width = rest.style && rest.style.width;

    switch (type) {
        case fieldTypes.select:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledFilterSelect
                        filterName={path}
                        values={value && single ? [value] : value ? value : []}
                        valuesPresentation={
                            valuesPresentation ||
                            (single
                                ? defaultSingleValuesPresentation(fieldName || label)
                                : defaultMultipleValuesPresentation(fieldName || label))
                        }
                        optionPresentation={optionPresentation || defaultOptionPresentation}
                        onChange={(path, value) => (single ? onChange(path, value[0]) : onChange(path, value))}
                        key={path}
                        single={single}
                        optionFilterPredicate={
                            optionFilterPredicate ||
                            getOptionFilterPredicate(optionPresentation || defaultOptionPresentation)
                        }
                        width={width}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.reactSelect:
            return creatable ? (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledReactSelect
                        creatable
                        placeholder={'Type for search'}
                        value={value || ''}
                        onChange={a => onChange(path, a)}
                        key={path}
                        width={width}
                        {...rest}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledReactSelect
                        placeholder={labeled ? null : fieldName || label}
                        value={value || ''}
                        onChange={a => onChange(path, a.value)}
                        key={path}
                        width={width}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.checkBox:
            return (
                <StyledCheckBox
                    checked={value || false}
                    key={path}
                    onChange={({ returnValue }) => onChange(path, returnValue)}
                    label={label || fieldName}
                    {...rest}
                />
            );

        case fieldTypes.textarea:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledTextArea
                        onChange={e => onChange(path, e.target.value)}
                        value={Number.isNaN(value) || value === undefined ? '' : value}
                        placeholder={labeled ? null : fieldName || label}
                        key={path}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.text:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledField
                        type={type}
                        onChange={e => onChange(path, e.target.value)}
                        value={Number.isNaN(value) || value === undefined ? '' : value}
                        placeholder={labeled ? null : fieldName || label}
                        key={path}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.date:
            return (
                <Fragment>
                    <StyledLabel>{label || fieldName}</StyledLabel>
                    <StyledField
                        type={type}
                        onChange={e => onChange(path, e.target.value)}
                        value={value || ''}
                        hideArrows
                        key={path}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.dateRange:
            return (
                <StyledDateRangeWrapper>
                    <StyledLabel>{fieldName}</StyledLabel>
                    <DateRangeField
                        type={'date'}
                        onChange={onChange}
                        value={value || {}}
                        hideArrows
                        key={path}
                        path={path}
                        leftPadding={'2rem'}
                        {...rest}
                    />
                </StyledDateRangeWrapper>
            );

        case fieldTypes.time:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledInputTime
                        onChange={value => onChange(path, value)}
                        value={value || ''}
                        key={path}
                        hideClear
                        hideSeconds={hideSeconds}
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.dateTime:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledInputDateTime
                        onChange={value => onChange(path, value)}
                        value={value || ''}
                        key={path}
                        hideClear
                        hideSeconds={hideSeconds}
                        hideArrows
                        {...rest}
                    />
                </Fragment>
            );

        case fieldTypes.searchableList:
            return (
                <Fragment>
                    {labeled ? <StyledLabel>{label || fieldName}</StyledLabel> : null}
                    <StyledFilterSelect
                        autocomplete={true}
                        filterName={path}
                        values={value && single ? [value] : value ? value : []}
                        valuesPresentation={
                            valuesPresentation ||
                            (single
                                ? defaultSingleValuesPresentation(fieldName || label)
                                : defaultMultipleValuesPresentation(fieldName || label))
                        }
                        optionPresentation={optionPresentation || defaultOptionPresentation}
                        onChange={(path, value) => (single ? onChange(path, value[0]) : onChange(path, value))}
                        key={path}
                        single={single}
                        optionFilterPredicate={
                            optionFilterPredicate ||
                            getOptionFilterPredicate(optionPresentation || defaultOptionPresentation)
                        }
                        width={width}
                        {...rest}
                    />
                </Fragment>
            );

        default:
            return <Fragment>Not implemented</Fragment>;
    }
};

export default Field;

export const getDefaultValues = props => fields =>
    fields
        .map(({ path, defaultValue }) => {
            if (!defaultValue) return null;
            if (isFunction(defaultValue) && !defaultValue(props)) return null;
            return {
                [path]: isFunction(defaultValue) ? defaultValue(props) : defaultValue
            };
        })
        .filter(value => !!value)
        .reduce((values, v) => Object.assign(values, v), {});

export const buildOptionFilterPredicate = (presentation = defaultOptionPresentation) => (option, str) => {
    try {
        //Case for translation (Formatted message at presentation)
        if (typeof presentation(option) === 'object') {
            return presentation(option);
        }

        return presentation(option)
            .toLowerCase()
            .includes((str && str.toLowerCase()) || '');
    } catch (e) {
        /*eslint-disable*/
        console.error(e);
        /*eslint-enable*/
        return presentation(option)
            .toLowerCase()
            .includes((str && str.toLowerCase()) || '');
    }
};
