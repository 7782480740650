import React from 'react';

import { CloseIcon } from 'components/close/close-icon';
import Loading from 'components/loading/loading';

import { CloseButtonWrapper, InputWrapper, StyledInput } from '../top-search-panel.styled';

export const InputField = ({ value, onChange, onCancel, processing, isSearchPanel = false }) => (
    <InputWrapper>
        <StyledInput autoFocus placeholder="Search" value={value} onChange={onChange} />
        <CloseButtonWrapper isSearchPanel={isSearchPanel} onClick={onCancel}>
            {processing && <Loading spinner />} <CloseIcon />
        </CloseButtonWrapper>
    </InputWrapper>
);
