import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import PrivateRoute from '../components/router/private-route';
import ScrollToTop from '../components/router/top-scroll';
import { roles, injectRoleManagement } from '../utils/permissions/role-utils';
import { NotFound } from '../components/error/error';
import FallbackPage from '../components/fallback-page/fallback-page';

import App from '../containers/app/index';
import UserContainer from '../containers/user';
import PageContainer from '../containers/page-container/index';
import { REPORT, productToPath } from '../containers/product-initializer/products';
import ProductInitializer from '../containers/product-initializer/product-initializer';
import WaiterOfProcess from '../components/waiter-of-process/waiter-of-process';
import SettingsWrapper from '../containers/setting-wrapper';

import { scoutAdminIndex, clientsAdminIndex, scheduleAdminIndex } from '../constants/page-names';

import LicensePage from '../pages/license/license-page';
import ResetPassword from '../pages/auth/reset-password';
import { AsyncPostGameReportsPage } from '../pages/async-pages';
import Login from '../pages/auth/login';
import SignUp from '../pages/auth/sign-up';
import EmailConfirmation from '../pages/email-confirmation/email-confirmation';
import SsoLogin from '../pages/auth/sso-login';
import ProgressIndicator from '../pages/progress-indicator/progress-indicator';
import MountNotifier from '../pages/progress-indicator/mount-notifier';
import MediaPlayerRoute from '../pages/media-player/media-player-lazy-route';
import ReportPurchaseReport from '../pages/report-purchase-report/purchase-page';
import ReportPurchaseStatus from '../pages/report-purchase-report/purchase-status';
import AuthByToken from '../pages/auth-by-token/auth-by-token';
import { SIGN_UP_IS_AVAILABLE } from '../pages/auth/auth-option-links';
import EmailUpdateByToken from '../pages/email-update-by-token';
import PasswordUpdate from '../pages/auth/password-update';

import { isNahlPlayerAccount, isProspectMode } from '../selectors/user/user-features-selector';

import './workaround-css-imports';
import Disabled from './disabled';
import {
    BackgroundBlurProvider,
    GlobalStyles,
    MeetingProvider,
    lightTheme
} from 'amazon-chime-sdk-component-library-react';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider } from 'styled-components';

const AdminRoutes = lazy(() => import(/* webpackChunkName: "admin-pages" */ '../pages/admin/admin'));
const ClientRoutes = lazy(() => import(/* webpackChunkName: "client-pages" */ '../pages/client/client-routes'));
const NahlPlayerRoutes = lazy(() =>
    import(/* webpackChunkName: "player-pages" */ '../pages/nahl-player/nahl-player-routes')
);
const CollectorRoutes = lazy(() => import(/* webpackChunkName: "collector-pages" */ '../pages/collector/collector'));

//object returned by `lazy` is not valid React-Router component
const AsyncAdminRoutes = props => <AdminRoutes {...props} />;
const AsyncCollectorRoutes = props => <CollectorRoutes {...props} />;

//todo: export page names from constants/page-names
const RootRedirect = injectRoleManagement(({ hasAnyRole, hasProduct }) => {
    if (hasAnyRole([roles.ADMIN, roles.SCOUT_ADMIN])) {
        return <Redirect to={`/${clientsAdminIndex.root}/${clientsAdminIndex.users}`} />;
    }
    if (hasAnyRole([roles.CLIENT, roles.ANALYST])) {
        for (let key in productToPath) {
            if (hasProduct(key)) {
                return <Redirect to={`${productToPath[key].path}/home`} />;
            }
        }
        return <NotFound />;
    }

    if (hasAnyRole([roles.NFT_DESIGNER, roles.NFT_MODERATOR, roles.NFT_VERIFIER, roles.NFT_MANAGER])) {
        // return <Redirect to={`/nft/home`} />;
        return <NotFound />;
    }

    return <Redirect to={`/${scheduleAdminIndex.root}/${scheduleAdminIndex.games}`} />;
});

const fallback = (
    <MountNotifier>
        <PageContainer>
            <FallbackPage />
        </PageContainer>
    </MountNotifier>
);

const Authorized = ({ nahlPlayerAccount, processing, prospectMode }) => (
    <>
        {!prospectMode && <ProgressIndicator />}
        <ThemeProvider theme={lightTheme}>
            <GlobalStyles />
            <BackgroundBlurProvider
                options={{
                    blurStrength: 20,
                    filterCPUUtilization: 20,
                    reportingPeriodMillis: 1000
                }}
            >
                <MeetingProvider enableWebAudio>
                    <UserContainer>
                        <Suspense fallback={fallback}>
                            <Switch>
                                <Route exact path="/" component={RootRedirect} />
                                <Route
                                    path={`/(${scoutAdminIndex.root}|${clientsAdminIndex.root}|${
                                        scheduleAdminIndex.root
                                    })`}
                                    component={AsyncAdminRoutes}
                                />
                                <Route path="/(collector|lite-collector|jobs)" component={AsyncCollectorRoutes} />
                                {!processing && !nahlPlayerAccount && <ClientRoutes />}
                                {!processing && nahlPlayerAccount && <NahlPlayerRoutes />}
                            </Switch>
                        </Suspense>
                    </UserContainer>
                </MeetingProvider>
            </BackgroundBlurProvider>
        </ThemeProvider>
    </>
);

const AuthorizedWrapper = connect(state => ({
    processing: !state.user || state.user.isProcessing,
    nahlPlayerAccount: isNahlPlayerAccount(state),
    prospectMode: isProspectMode(state)
}))(Authorized);

const ReportProduct = props => (
    <ProductInitializer product={REPORT}>
        <AsyncPostGameReportsPage {...props} />
    </ProductInitializer>
);

const isDisableAll = false;

const Routes = () => {
    if (isDisableAll) {
        return <Disabled />;
    }

    return (
        <App>
            <SettingsWrapper>
                <ScrollToTop>
                    <Switch>
                        <Route path="/login" component={Login} />
                        {!!SIGN_UP_IS_AVAILABLE && <Route path="/sign-up" component={SignUp} />}
                        <Route path="/reset" component={ResetPassword} />
                        <Route path="/confirmation" component={EmailConfirmation} />
                        <Route path="/set-password" component={PasswordUpdate} />
                        <Route path="/update-email" component={EmailUpdateByToken} />
                        <Route path="/license" component={LicensePage} />
                        <Route path="/game-reports" component={ReportProduct} />
                        <Route path="/sso-login" component={SsoLogin} />
                        <Route path="/media-player" component={MediaPlayerRoute} />
                        <Route path="/player-report/fail" component={() => <ReportPurchaseStatus failure />} />
                        <Route path="/player-report/success" component={() => <ReportPurchaseStatus success />} />
                        <Route path="/player-report" component={ReportPurchaseReport} />
                        <Route path="/authorize" component={AuthByToken} />
                        <PrivateRoute component={AuthorizedWrapper} />
                    </Switch>
                </ScrollToTop>
                <WaiterOfProcess />
            </SettingsWrapper>
        </App>
    );
};

export default Routes;
