/* eslint-disable no-useless-escape */

export const urlIsValid = urlToCheck => !!urlToCheck && /^(ftp|http|https):\/\/[^ "]+$/.test(urlToCheck);

export const isEmailValid = emailToCheck => {
    if (!emailToCheck || emailToCheck === '') {
        return false;
    }
    return emailToCheck && /[^\.@]+@[^\.@]+\.[^\.@]+/.test(emailToCheck);
};

const protocolRegEx = /(https?:\/\/)/;
const domainRegEx = /([\w\-]+\.)+[\w\-]+/;
const portRegEx = /(:\d+)?/;
const pathRegEx = /(\/([\w\-\.]*(\([\w\-\.]*\))?)*)*/;
const queryRegEx = /(\?([\w\-]+=[\w\+\.\-@^%;~]+&?)+)?/;
const hashRegEx = /(#[\w\-]+)?/;

export const buildUrlRegex = strict =>
    new RegExp(
        protocolRegEx.source +
            (strict ? '' : '?') +
            domainRegEx.source +
            portRegEx.source +
            pathRegEx.source +
            queryRegEx.source +
            hashRegEx.source,
        'g'
    );

export const onlyNumbersAndSpacesAllowedCheck = checkedString =>
    checkedString !== '' && !/^[\d\s]+$/.test(checkedString);
