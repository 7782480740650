import requestBuilder from '../../redux/utils/request-builder';
import { makeBundle, makePrefixAdder } from '../../redux/utils/type-utils';
import * as endpoints from '../../utils/api/endpoints';
import { gameSchema } from '../../utils/schemas';
import { fromStrengthsMask, toStrengthsMaskIndex } from '../../utils/strengths';
import { REQUEST } from '../widgets-stats/fetch-stats-types';
import { handleDynamicOptions, nameToRequest } from './dynamic-filter-options';
import mergeStoredStates from './merge-stored-states';
import {
    SUPER_LITE,
    PRE_SCOUT,
    PREMIUM,
    PREMIUM_PLUS,
    PRO,
    REPORT,
    SCOUT,
    // NFT,
    NAHL
} from 'containers/product-initializer/products';

const addPrefixTo = makePrefixAdder('statsFilter');
const initializeGamesBundle = makeBundle(addPrefixTo('GAMES_INIT'));

//todo delete later
const FILTER_UPDATE = addPrefixTo('FILTER_UPDATE');

const STORED_STATES_UPDATE = addPrefixTo('STORED_STATES');

//todo delete later
const HANDLE_REDIRECT = addPrefixTo('HANDLE_REDIRECT');
//todo delete later
const FILTERS_RESET = addPrefixTo('FILTERS_RESET');

//todo replace filter on scout page and delete
const SCOUT_FILTER_INITIALIZE = addPrefixTo('SCOUT_FILTER_INITIALIZE');
const SCOUT_FILTER_CLEAN_UP = addPrefixTo('SCOUT_FILTER_CLEAN_UP');

const __FORCE_SETTING_GAME_IDS__ = addPrefixTo('__FORCE_SETTING_GAME_IDS__');

const RESET = addPrefixTo('RESET');

const SET_UPLOADED_GAMES = addPrefixTo('SET_UPLOADED_GAMES');

const SET_IN_PROGRESS_GAMES = addPrefixTo('SET_IN_PROGRESS_GAMES');

const SET_PROCESSED_GAMES = addPrefixTo('SET_PROCESSED_GAMES');

export const types = { FILTER_UPDATE, __FORCE_SETTING_GAME_IDS__, RESET };

const productToAvailableGameProducts = {
    [PRO]: [PRO],
    [PREMIUM_PLUS]: [PRO, PREMIUM_PLUS],
    [PREMIUM]: [PRO, PREMIUM_PLUS, PREMIUM],
    [REPORT]: [PRO, PREMIUM_PLUS, PREMIUM],
    [PRE_SCOUT]: [PRE_SCOUT],
    [SCOUT]: [SCOUT],
    [SUPER_LITE]: [SUPER_LITE]
    //[NFT]: [NFT]
};

/**
 * Specify games productTypes
 */
const initializeGames = (product = PRO, userTeamId, prospectMode, prospectPlayerId, exceptConditions = false) => {
    const playerId = window.location.pathname.split('/')[2];
    if (product === SCOUT && Number.isInteger(+playerId)) {
        return requestBuilder(initializeGamesBundle)
            .addSchema([gameSchema])
            .get(endpoints.scoutPlayerGames(playerId));
    }

    const EXCEPTION_PRODUCT_CONDITIONS =
        !!prospectMode || product === NAHL || product === PREMIUM || product === PREMIUM_PLUS;

    const params = {
        productTypes: EXCEPTION_PRODUCT_CONDITIONS
            ? [PRO, PREMIUM_PLUS, PREMIUM, SUPER_LITE].join()
            : productToAvailableGameProducts[product].join()
    };

    if (userTeamId) {
        params.teamId = userTeamId;
    }

    if ((!!prospectMode && !!prospectPlayerId) || exceptConditions) {
        params.playerId = prospectPlayerId;
    }

    return requestBuilder(initializeGamesBundle)
        .addSchema([gameSchema])
        .get(endpoints.games, { params });
};

/**
 * Specific action for Analyst account
 */
const initializeAnalystGames = (product = PRO, prospectMode) => {
    const params = {
        productTypes: prospectMode
            ? [PRO, PREMIUM_PLUS, PREMIUM, SUPER_LITE]
                  .filter(el => (product === PREMIUM || product === PREMIUM_PLUS ? el !== PRO : el))
                  .join()
            : productToAvailableGameProducts[product]
                  .filter(el => (product === PREMIUM || product === PREMIUM_PLUS ? el !== PRO : el))
                  .join()
    };

    return requestBuilder(initializeGamesBundle)
        .addSchema([gameSchema])
        .get(endpoints.games, { params });
};

function updateFilter(filter) {
    return { type: FILTER_UPDATE, payload: filter };
}

function updateStoredStates(storedStates) {
    return { type: STORED_STATES_UPDATE, payload: storedStates };
}

//todo delete later
function handleRedirect() {
    return { type: HANDLE_REDIRECT }; //very bad kostil!!
}

//todo delete later
function resetFilters(payload) {
    return { type: FILTERS_RESET, payload };
}

//todo delete later
function initializeScoutFilter() {
    return { type: SCOUT_FILTER_INITIALIZE };
}

//todo delete later
function cleanUpScoutFilter() {
    return { type: SCOUT_FILTER_CLEAN_UP };
}

function initializedDynamicOptions(dynamicOptionsName, ...args) {
    const { request } = nameToRequest[dynamicOptionsName];
    return request(...args);
}

function __forceSettingGameIds__(gameIds) {
    return { type: __FORCE_SETTING_GAME_IDS__, gameIds };
}

function resetFilterStats() {
    return { type: RESET };
}

const setUploadedGames = (games = []) => ({
    type: SET_UPLOADED_GAMES,
    payload: games
});

const setInProgressGames = (games = []) => ({
    type: SET_IN_PROGRESS_GAMES,
    payload: games
});

const setProcessedGames = (games = []) => ({
    type: SET_PROCESSED_GAMES,
    payload: games
});

//todo: think about forceSettingGameIds & reset filters & filterResetter
//todo: possibly there should be a work around with additionalFilterProps
export const actions = {
    initializeScoutFilter,
    cleanUpScoutFilter,
    initializeGames,
    initializeAnalystGames,
    updateFilter,
    handleRedirect,
    resetFilters,
    initializedDynamicOptions,
    updateStoredStates,
    resetFilterStats,
    __forceSettingGameIds__,
    setUploadedGames,
    setInProgressGames,
    setProcessedGames
};

const defaultState = {
    initialized: false,
    fetchedGameIds: [],
    dynamicOptions: {},
    gameIds: [],
    teamIds: [],
    storedStates: {},
    additionalFilterProps: {},
    uploadedGames: [],
    inProgressGames: [],
    processedGames: []
};

function buildFilterSearchParamsState(search) {
    const searchParams = new URLSearchParams(search);
    const state = {};
    if (searchParams.has('gameIds')) {
        state.gameIds = searchParams
            .get('gameIds')
            .split(',')
            .map(id => parseInt(id));
    }
    if (searchParams.has('playerIds')) {
        state.playerIds = searchParams
            .get('playerIds')
            .split(',')
            .map(id => parseInt(id));
    }
    if (searchParams.has('teamIds')) {
        state.teamIds = searchParams
            .get('teamIds')
            .split(',')
            .map(id => parseInt(id));
    }
    if (searchParams.has('strengths')) {
        state.strengths = fromStrengthsMask(parseInt(searchParams.get('strengths'))).map(strength =>
            toStrengthsMaskIndex(strength)
        );
    }
    return state;
}

const searchParamsState = buildFilterSearchParamsState(window.location.search);

const initialState = { ...defaultState, ...searchParamsState };

export function reducer(state = initialState, action) {
    switch (action.type) {
        case RESET:
            return initialState;
        case __FORCE_SETTING_GAME_IDS__: {
            const fetchedGameIds = Array.from(new Set([...state.fetchedGameIds, ...action.gameIds]));

            return {
                ...state,
                initialized: true,
                processing: false,
                gameIds: action.gameIds,
                fetchedGameIds
            };
        }
        //=============== start workaround for scout page ==================
        case SCOUT_FILTER_INITIALIZE:
            return { ...state, initialized: true, processing: false };
        case SCOUT_FILTER_CLEAN_UP:
            return initialState;
        //=============== end workaround for scout page ====================
        case initializeGamesBundle.REQUEST:
            return { ...state, initialized: true, processing: true };
        case initializeGamesBundle.SUCCESS:
            return {
                ...state,
                fetchedGameIds: action.response.result,
                processing: false
            };
        case initializeGamesBundle.FAILURE:
        case initializeGamesBundle.CANCELLED:
            return { ...initialState, initialized: false };
        case FILTER_UPDATE:
            return { ...state, ...action.payload };
        //todo delete later
        case FILTERS_RESET:
            return {
                ...state,
                ...action.payload
            };
        case STORED_STATES_UPDATE:
            return {
                ...state,
                storedStates: mergeStoredStates(state.storedStates, action.payload)
            };
        case REQUEST:
            // 	for backward compatibility
            if (action.payload.legacy) return state;

            return {
                initialized: state.initialized,
                processing: state.processing,
                dynamicOptions: state.dynamicOptions,
                fetchedGameIds: state.fetchedGameIds,
                storedStates: mergeStoredStates(state.storedStates, action.payload.filterToStoredState),
                additionalFilterProps: action.payload.additionalFilterProps || state.additionalFilterProps,
                ...action.payload.filterToValues
            };
        case SET_UPLOADED_GAMES:
            return {
                ...state,
                uploadedGames: action.payload
            };
        case SET_IN_PROGRESS_GAMES:
            return {
                ...state,
                inProgressGames: action.payload
            };
        case SET_PROCESSED_GAMES:
            return {
                ...state,
                processedGames: action.payload
            };
        default:
    }
    return handleDynamicOptions(state, action);
}

export const selector = state => state.statsFilter;
