import { useCallback, useEffect, useRef } from 'react';

export const useOutsideClick = ({ isMobile, handeVisible, isVisible }) => {
    const containerRef = useRef();

    const handleClickOutside = useCallback(
        e => {
            if (!isMobile) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(e.target)) {
                handeVisible(false);
            }
        },
        [containerRef, handeVisible, isMobile]
    );

    useEffect(() => {
        if (!isVisible || !isMobile) {
            return;
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside);
        };
    }, [handleClickOutside, isVisible, isMobile]);

    return containerRef;
};

export const useOutsideClickContainer = ({ handelVisible }) => {
    const containerRef = useRef();
    const handleClickOutside = useCallback(
        event => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handelVisible();
            }
        },
        [containerRef]
    );

    useEffect(() => {
        /**
         * if clicked on outside of element
         */
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.addEventListener('scroll', handleClickOutside);
        };
    }, [containerRef]);
    return containerRef;
};
