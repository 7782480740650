import React, { useState, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import Input from '../../components/input/input';
import FormattedMessage from '../../components/formatted-message';
import { IconEyeOff } from '../../components/svg-icons/eye-off';
import { IconEyeOn } from '../../components/svg-icons/eye-on';
import * as endpoints from '../../utils/api/endpoints';
import request from '../../utils/api/common-requests/common-requests';
import { decodeSearchUrl } from '../../utils/api/path-utils';
import { checkPasswordFieldCondition } from '../../utils/helpers/string-utils';

import {
    StyledButton,
    StyledContainer,
    StyledError,
    PseudoStyledForm,
    StyledHeader,
    WithControlButtonWrap,
    ButtonControl,
    FieldError,
    FieldErrorWrapper
} from './auth-styled-components';

const ERROR_TIMEOUT_MS = 5000;

const PasswordUpdate = () => {
    const { login, token } = decodeSearchUrl();

    const [isVisiblePass, setPassVisibility] = useState(false);
    const [isVisiblePassConfirm, setPassConfirmVisibility] = useState(false);

    const [isError, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const handlePasswordView = useCallback(
        e => {
            e.preventDefault();
            setPassVisibility(!isVisiblePass);
        },
        [isVisiblePass]
    );

    const handlePasswordConfirmView = useCallback(
        e => {
            e.preventDefault();
            setPassConfirmVisibility(!isVisiblePassConfirm);
        },
        [isVisiblePassConfirm]
    );

    const handleValidateCheck = useCallback(values => {
        const errors = {};

        const { password, passwordConfirm } = values;

        const passwordPrecondition = checkPasswordFieldCondition(password);

        if (passwordPrecondition) {
            errors.password = passwordPrecondition;
        }

        const passwordConfirmPrecondition = checkPasswordFieldCondition(passwordConfirm);

        if (passwordConfirmPrecondition) {
            errors.passwordConfirm = passwordConfirmPrecondition;
        }

        if (password !== passwordConfirm) {
            errors.passwordConfirm = 'Must be equal';
        }

        return errors;
    }, []);

    const handleFormSubmit = useCallback(
        async values => {
            const { password } = values;

            setError(null);
            setLoading(true);
            setRedirectToReferrer(false);

            try {
                const { status, data } = await request.get(endpoints.passwordSet(token, password));

                setLoading(false);

                if (status === 200) {
                    setRedirectToReferrer(true);
                } else {
                    // eslint-disable-next-line
                    console.error('Incorrect response from server.');

                    // eslint-disable-next-line
                    data && console.log(data);

                    setError(true);
                }
            } catch (e) {
                // eslint-disable-next-line
                console.error(e);

                setError(true);
                setLoading(false);
                setRedirectToReferrer(false);
            }
        },
        [token]
    );

    useEffect(() => {
        if (!isError) {
            return;
        }

        let resetErrorTimeout;

        resetErrorTimeout = setTimeout(() => {
            setError(false);
        }, ERROR_TIMEOUT_MS);

        return () => {
            clearTimeout(resetErrorTimeout);
        };
    }, [isError]);

    if (redirectToReferrer) {
        return <Redirect to={'/login'} />;
    }

    return (
        <StyledContainer>
            <Form
                onSubmit={handleFormSubmit}
                validate={handleValidateCheck}
                render={({ handleSubmit, form, submitting, errors, values }) => (
                    <form onSubmit={handleSubmit}>
                        <PseudoStyledForm>
                            <StyledHeader ordinary>
                                {login ? `Hi, ${login}` : ''}

                                <br />

                                <FormattedMessage id="password-update.title" />
                            </StyledHeader>

                            <Field name="password">
                                {({ input, meta }) => (
                                    <>
                                        <WithControlButtonWrap>
                                            <Input
                                                {...input}
                                                type={isVisiblePass ? 'text' : 'password'}
                                                placeholder="Password"
                                            />

                                            <ButtonControl onClick={handlePasswordView}>
                                                {isVisiblePass ? <IconEyeOff /> : <IconEyeOn />}
                                            </ButtonControl>
                                        </WithControlButtonWrap>
                                        <FieldErrorWrapper>
                                            {meta.error && meta.touched && <FieldError>{meta.error}</FieldError>}
                                        </FieldErrorWrapper>
                                    </>
                                )}
                            </Field>

                            <Field name="passwordConfirm">
                                {({ input, meta }) => (
                                    <>
                                        <WithControlButtonWrap>
                                            <Input
                                                {...input}
                                                type={isVisiblePassConfirm ? 'text' : 'password'}
                                                placeholder="Confirm password"
                                            />

                                            <ButtonControl onClick={handlePasswordConfirmView}>
                                                {isVisiblePassConfirm ? <IconEyeOff /> : <IconEyeOn />}
                                            </ButtonControl>
                                        </WithControlButtonWrap>
                                        <FieldErrorWrapper>
                                            {meta.error && meta.touched && <FieldError>{meta.error}</FieldError>}
                                        </FieldErrorWrapper>
                                    </>
                                )}
                            </Field>

                            <StyledButton
                                type="submit"
                                disabled={submitting || Object.keys(errors).length > 0}
                                isLoading={isLoading}
                            >
                                <FormattedMessage id="password-update.create" />
                            </StyledButton>

                            {Object.keys(errors).length > 0 && Object.keys(values).length > 0 && (
                                <StyledError>Check fields</StyledError>
                            )}

                            {isError && (
                                <StyledError>
                                    <FormattedMessage id="password-update.error" />
                                </StyledError>
                            )}
                        </PseudoStyledForm>
                    </form>
                )}
            />
        </StyledContainer>
    );
};

export default PasswordUpdate;
