export const LicenseContent = () => (
    <>
        <p>
            <strong>The Prospect Exchange (&ldquo;TPE&rdquo;) Terms of Use</strong>
        </p>
        <p>
            These terms of use (&ldquo;<strong>Terms of Use</strong>&rdquo;) describe and govern the relationship
            between TPE (also referred to a &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;the
            company&rdquo;) and you (which will also be deemed to refer to the Entity that you represent) with respect
            to your use of the TPE website &ndash; www.theprospectexchange.com (collectively, with each portal or other
            domain accessed via the TPE website, collectively, &ldquo;
            <strong>Site</strong>&rdquo;) and your use of the services provided by TPE through the Site (the &ldquo;
            <strong>Services</strong>
            &rdquo;).
        </p>
        <p>
            By using the Services, you agree to be bound by these Terms of Use, whether you are a visitor, which means
            that you simply browse the Site or any of its applications, or you are a user with a registered account
            (&ldquo;<strong>Registered User</strong>&rdquo;). TPE may at any time in its sole discretion (a) modify
            these Terms of Use and such modification shall be effective once posted to the Site; (b) change the Site,
            including eliminating or discontinuing any Services or other feature of the Site; and/or (c) deny or
            terminate your use of and/or access to the Site.
        </p>
        <p>
            You agree to be bound to any changes to these Terms of Use when you use the Services or access the Site
            immediately after any modification to these Terms of Use has been posted. It is therefore important that you
            review these Terms of Use regularly to ensure you are updated as to any changes.
        </p>
        <p>
            The term &ldquo;<strong>User</strong>&rdquo; refers to any visitor to the Site or Registered User, unless
            provided otherwise. You are only authorized to use the Services if you agree to abide by all applicable
            laws, these Terms of Use and any subscription or services agreement that you enter into from time to time (a
            &ldquo;
            <strong>Customer Agreement</strong>&rdquo;). Please read these Terms of Use carefully and save it. If you do
            not agree with it, you should leave the Site or any of its applications and discontinue use of the Services
            immediately. If you wish to become a Registered User, communicate with other Users, and make full use of the
            Services, you must read these Terms of Use and indicate your acceptance during the registration process.
        </p>
        <p>
            The terms and conditions of any Customer Agreement are incorporated herein by reference and area a part of
            these Terms of Use.
        </p>
        <p>
            <strong>
                DO NOT ACCESS OR USE THE SITE OR THE SERVICES IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE
                TERMS OF USE.{' '}
            </strong>
        </p>
        <ol>
            <li>
                <u>Definitions</u>.
            </li>
        </ol>
        <p>
            &ldquo;<strong>Authorised User</strong>&rdquo; means each user of your Entity which will be identified by
            you via the Site or Customer Agreement.
        </p>
        <p>
            &ldquo;<strong>Entity</strong>&rdquo; means the organization, team, league or other entity that you
            represent when establishing an account on the Site.
        </p>
        <p>
            &ldquo;<strong>Feedback</strong>&rdquo; means any comments, information, questions, survey data, data,
            ideas, enhancement requests, recommendations, descriptions of processes, or other information concerning the
            Site or the Services, whether solicited by TPE or provided by the Entity or its Authorized Users without any
            such solicitation.
        </p>
        <p>
            &ldquo;<strong>TPE Representatives</strong>&rdquo; means TPE&rsquo;s officers, directors, employees,
            licensees, sub-licensees, advisors and representatives, and TPE&rsquo;s affiliates and its affiliates&rsquo;
            officers, directors, employees, licensees, sub-licensees, advisors and representatives.
        </p>
        <p>
            &ldquo;<strong>Intellectual Property Rights</strong>&rdquo; means any rights under any patent (including
            patent applications and disclosures), copyright, trademark, trade secret, know-how or other intellectual
            property right recognized under applicable law or regulation in any jurisdiction.
        </p>
        <p>
            &ldquo;<strong>Video</strong>&rdquo; means video clips, video fragments and other game or practice film
            including the voices, performances, poses, acts, plays, appearances pictures, images, likeness, photo
            groups, silhouettes and other reproductions of the physical likeness and sound of the players, and all
            others appearing in such video.
        </p>
        <ol start="2">
            <li>
                <u>Eligibility</u>. Use of the Services is void where prohibited by applicable law or regulation. By
                using the Services and accessing the Site, you represent and warrant to us that (a) you are not
                prohibited from accessing the Site or using the Services, (b) any information submitted by you through
                the Site or a Customer Agreement is truthful, accurate and otherwise owned by (or licensed to) you; and
                (c) your use of the Services or access to the Site does not violate any applicable law or regulation.
            </li>
            <li>
                <u>Purchase of Services</u>. You must designate at least one individual as the administrator of your
                account (the &ldquo;
                <strong>Customer Administrator</strong>&rdquo;). The Customer Administrator has authority to make
                changes to your account, to remove or add other users from your account (including other Customer
                Administrators) and to take any other actions and obtain any other information related to you. You are
                responsible for the actions of your Customer Administrators. Access to certain functionalities of the
                Site and the Services will require you to provide certain information to us, register via the Site
                and/or enter into a Customer Agreement. By agreeing to purchase Services you give us permission to
                obtain and process your payment information and that we may retain and process such information (i.e.
                credit card, banking information, etc.) even after it has expired for administrative and taxation
                purposes.
            </li>
        </ol>
        <p>
            We reserve the right to decline to provide Services to any person for any reason or no reason. When you
            indicate your intent to become a Registered User, you will be required to create a password and you will be
            solely responsible for maintaining the confidentiality of such password.
        </p>
        <p>
            You agree not to (a) access the Site or use the Services via the account, username or password of another
            User at any time or (b) disclose your password or username to any third party. You agree to notify us
            immediately if you suspect any unauthorized use of your account or access to your password and you are
            solely responsible for any and all uses of your account.
        </p>
        <p>
            By clicking through and purchasing a subscription either via the Site or a Customer Agreement, you agree
            that such subscription will be automatically renewed and, unless you cancel your subscription, you authorize
            us to charge your payment method for the renewal term. By providing payment method information for a
            subscription, you are agreeing to pay the relevant subscription fee and any applicable taxes and service
            fees (the &ldquo;<strong>Fees</strong>&rdquo;) in connection with your use of the Services. The Fees will be
            set out in an invoice provided to you via the Site or email once you first register and charged
            automatically at the beginning of each subscription period and renewal thereof unless you cancel your
            subscription prior to such renewal pursuant to these Terms of Use or the Customer Agreement. The Fees are
            not refundable or cancelable (unless stated otherwise on the invoice or the Customer Agreement, if
            applicable). TPE may change the Fees at any time without notice to you and such revised Fees shall become
            effective on your subscription upon the next renewal. Unless otherwise provided in a Customer Agreement, use
            of the Services will not be possible without pre-payment and failure to make such pre-payment may result in
            our deactivation of the Services and your access to the Site.
        </p>
        <p>
            Subject to the terms of any Customer Agreement or invoice, you may cancel any subscription or purchase of
            Services made via the Site or a Customer Agreement by contacting us at xxxxx@theprospectexchange.com or by
            logging into your account and clicking &ldquo;Cancel subscription&rdquo;. If you cancel your subscription,
            the cancellation will go into effect at the end of the then current, pre-paid subscription period and no
            unused portion of such period will entitle you to a refund of any Fees previously paid with respect thereto.
        </p>
        <p>
            You acknowledge and agree that the Services and the Site are supplied solely for the benefit of you and
            shall be used solely for your internal analytical purposes only and for no other purpose. Other than as may
            be required by law or regulation or with the prior written consent of TPE, the Services, the results
            thereof, and any Video or Feedback associated therewith, provided hereunder shall not be transferred,
            licensed or distributed to any third party and not commercialized for any purpose. For the avoidance of
            doubt, to the extent the Services include any Video provided by TPE or to which you provided TPE with
            access, you agree that such Video is being provided solely for your internal use and cannot in any form be
            reproduced, transferred, distributed or commercialized in any manner.
        </p>
        <ol start="4">
            <li>
                <u>Data Requests</u>.
            </li>
        </ol>
        <p>
            When you or the Entity requests any data to be uploaded by its Authorized Users, TPE will follow the
            procedures described in its Privacy Policy (which can be found at [ ]).
        </p>
        <ol start="5">
            <li>
                <u>Licenses</u>.
            </li>
        </ol>
        <p>
            5.1 TPE Limited License. Subject to your payment of the Fees, TPE grants to you the non-exclusive,
            non-transferable, revocable right during the Term (as defined below) to access the Site and use the Services
            and authorizes you to copy materials on this Site to your hard drive solely for the purpose of viewing and
            using the Services on your computer for your internal purposes by you and any Authorised Users. You may also
            print portions of the Site in hard copy for the sole purpose of facilitating your personal, noncommercial
            use and retention of information from the Site, provided, that (i) you must retain all trademark, copyright,
            and other proprietary notices contained in the original materials, (ii) you must provide attribution to TPE,
            and provide a reference to the Site, (iii) the material must be printed in its entirety without
            modification, reformatting or adaptation of any kind, (iv) any such copies are subject to the terms and
            conditions of these Terms of Use and remain the property of TPE, and (v) you agree to provide any person
            with whom you share the materials with a copy of these Terms of Use and they must agree to abide by these
            Terms of Use. You may not sell, sub-license, distribute, transfer or modify the material or reproduce,
            republish, display, publicly perform, distribute, or otherwise use the material in any way for any public or
            commercial purpose other than through the Services or the Site. Your unauthorized use of the Services for
            any other purpose is prohibited. You may not decompile, reverse engineer, disassemble, rent, lease, loan,
            sell, sublicense, or create derivative works from the Site or the Services. Nor may you use any network
            monitoring or discovery software to determine the site architecture, or extract information about usage or
            users. You may not use any robot, spider, other automatic device, or manual process to monitor or copy our
            Site or its content without our prior written permission. Failure to abide by these conditions will
            immediately terminate this permission and may result in the infringement of the Intellectual Property Rights
            of TPE or others.
        </p>
        <p>
            5.2 User Grants to TPE. Subject to these Terms of Use and any Customer Agreement, you agree that TPE and TPE
            Representatives shall have the following rights:
        </p>
        <p>
            (i) to the extent that you provide TPE with access to Video via the Site or otherwise for the purposes of
            overlaying Video on the data and other content provided as part of the Services, you (a) grant TPE a
            non-exclusive, royalty free, irrevocable license/sub-license to use the Intellectual Property Rights
            associated with such Video for the purposes of providing such Services, including to reproduce, transmit,
            display, exhibit, distribute, index, comment on, modify, create derivative works based upon (including
            inserting advertising therein), perform and otherwise use such released Video, in whole or in part, in
            perpetuity in all media formats and channels now known or hereafter devised (including on the Site, third
            party websites, cable networks and stations, broadband and wireless platforms, products and services) for
            any and all purposes, including entertainment, news, advertising, promotional, marketing, publicity, trade
            or commercial purposes, all without further notice to, or permission from you, with or without attribution
            and without any royalty or payment obligations, which rights in this subsection (i) shall survive any
            termination or expiration of these Terms of Use, and TPE hereby accepts such grant; and (b) agree to provide
            all proper documents and agreements evidencing that Video provided from third party sources was provided to
            you legally and can be legally transferred to TPE and the TPE Representatives for the purposes of providing
            the Services;
        </p>
        <p>
            (ii) the non-exclusive, royalty-free and perpetual right to all Intellectual Property Rights of your
            organization and its Authorized Users in the Vital Information and the physical characteristics (height and
            weight, etc.) and contact information concerning any Authorized User, as input by you or an Authorized User
            into the Site or otherwise as may be publicly available in connection with the Site and the Services;
        </p>
        <p>
            (iii) the non-exclusive, royalty-free and perpetual right, to all Intellectual Property Rights of your
            organization to use the rosters and performance statistics of your organization/team and each Authorized
            User, as inputted by an Authorized User into the Site; and
        </p>
        <p>
            (iv) the right to publicize and market the Entity as a customer. Subject to TPE&rsquo;s confidentiality
            obligations hereunder, TPE may display on the Site and in any and all promotional materials (irrespective of
            the means of exploitation) a case study or other customer usage scenario referencing or featuring the
            Entity. TPE may prepare and utilize testimonials of a reasonable number of Authorized Users. The Entity
            hereby grants to TPE, and represents to TPE that it may grant, a non-exclusive, non-transferable,
            royalty-free license for TPE to make use of the Entity&rsquo;s name or logo during the Term on the Site and
            in any and all promotional materials (irrespective of the means of exploitation).
        </p>
        <p>
            5.3 Ownership of Intellectual Property Rights. Except for the licenses provided in this Section, as between
            TPE and the Entity, (i) TPE owns all Intellectual Property Rights in the Site, the Services, TPE&rsquo;s
            marks and business model, Feedback, the data and analytics resulting therefrom and any other rights not
            expressly granted to Entity herein and (ii) the Entity retains all Intellectual Property Rights in the Video
            to which it provided TPE with access.
        </p>
        <p>
            Statistical Data. Other than as expressly set forth herein, no other licenses are granted by TPE to you. You
            shall not sub-license, assign or otherwise transfer the any rights (whether in Intellectual Property Rights
            or otherwise) granted or licensed to you hereunder or under the Customer Agreement.
        </p>
        <p>
            5.4 Feedback. TPE owns all Intellectual Property Rights in any Feedback and may use such Feedback for any
            purpose related to the Platform without further approval or acknowledgement, and Entity, on behalf of itself
            and its Authorized Users, hereby assigns to TPE any and all rights in such Feedback throughout the universe
            in perpetuity.
        </p>
        <ol start="6">
            <li>
                <u>User Conduct</u>.
            </li>
        </ol>
        <p>
            You agree not to use the Services or the Site to take any action(s) that, (and your continued use of the
            Site and Services are conditioned on not taking any action(s) that):
        </p>
        <ul>
            <li>
                are patently offensive and promote racism, bigotry, hatred or physical harm of any kind against any
                group or individual; harasses or advocates harassment of another person or group; exploits people in a
                sexual or violent manner; or contains nudity, violence, or offensive subject matter or contains a link
                to an adult website;
            </li>
            <li>
                solicits personal information; provides any User telephone numbers, street addresses, last names, URLs
                or email addresses; involves the transmission of &ldquo;junk mail,&rdquo; &ldquo;chain letters,&rdquo;
                or &ldquo;unsolicited mass mailing&rdquo;, &ldquo;instant messaging&rdquo;, &ldquo;phishing&rdquo;,
                &ldquo;spimming&rdquo; or &ldquo;spamming&rdquo;; contains restricted or password only access pages or
                hidden pages or images (those not linked to or from another accessible page);
            </li>
            <li>
                promotes information that you know is false or misleading or promotes illegal activities or conduct that
                is abusive, threatening, obscene, defamatory or libelous; promotes an illegal or unauthorized copy of
                another person&rsquo;s copyrighted work, such as providing pirated computer programs or links to them,
                providing information to circumvent manufacture-installed copy-protect devices, or providing pirated
                music or video or links to pirated files;
            </li>
            <li>
                furthers or promotes any criminal activity or enterprise or provides instructional information about
                illegal activities including, but not limited to making or buying illegal weapons, violating
                someone&rsquo;s privacy, or providing or creating computer viruses;
            </li>
            <li>
                involves commercial activities and/or sales without our prior written consent such as contests,
                sweepstakes, barter, advertising, or pyramid schemes;
            </li>
            <li>are contrary to TPE&rsquo;s public image, goodwill, or reputation;</li>
            <li>
                infringe on our or any third party&rsquo;s Intellectual Property Rights or rights of publicity or
                privacy;
            </li>
            <li>
                express or imply that any of your statements, activities or causes are endorsed by us, without our prior
                written consent in each instance;
            </li>
            <li>
                transmit any trade secret or other material, non-public information about any person, company or entity
                without the authorization to do so;
            </li>
            <li>
                &ldquo;frame&rdquo; or &ldquo;mirror&rdquo; any part of the Site without our prior written
                authorization;
            </li>
            <li>distribute any virus, worm or other similar or deleterious files, scripts or programming routines;</li>
            <li>
                interfere with or disrupt any services or equipment with the intent of causing an excessive or
                disproportionate load on the infrastructure of Agile or its licensors or suppliers;
            </li>
            <li>
                forge headers or otherwise manipulate identifiers in order to disguise the origin of any submission;
            </li>
            <li>
                override any security feature or bypass or circumvent any access controls or use limits of the Site;
            </li>
            <li>
                rent, lease, loan, trade sell/re-sell or otherwise monetize the Services or related data or access to
                the same, without TPE&rsquo;s advisor; and/or
            </li>
            <li>
                execute any form of network monitoring or run a network analyzer or packet sniffer or other technology
                to intercept, decode, mine or display any packets used to communicate between the Website&rsquo;s
                servers or any data not intended for you.
            </li>
        </ul>
        <p>Further, you agree not to use the Services or the Site to participate in:</p>
        <ul>
            <li>
                criminal or tortious activity, including child pornography, fraud, trafficking in obscene material, drug
                dealing, gambling, harassment, stalking, spamming, spimming, sending of viruses or other harmful files,
                trademark infringement, passing off, copyright infringement, patent infringement, or theft of trade
                secrets or violation of the privacy or publicity rights of third parties;
            </li>
            <li>
                advertising to, or solicitation of, any User to buy or sell any products or services through the
                Services. You may not transmit any chain letters or junk email to other Users. It is also a violation of
                these rules to use any information obtained from the Services in order to contact, advertise to,
                solicit, or sell to any User without their prior explicit consent. In order to protect our Users from
                such advertising or solicitation, TPE reserves the right to restrict the number of emails that a User
                may send to other Users;
            </li>
        </ul>
        <p>
            <strong>
                You agree not to use the Services or the Site in any manner that violates or is otherwise not in
                accordance with the terms of the subscription package in which you are currently enrolled
                (&ldquo;Account&rdquo;).
            </strong>
        </p>
        <p>
            We receive and store certain types of information when you visit or interact with the Site. For example, we
            use &ldquo;cookies&rdquo; and we obtain certain types of information when your web browser accesses the
            Site. Cookies are necessary for the good functioning of the Site and provision of the Services and they
            assist us in improving the Services and to give you extra functionality.
        </p>
        <ol start="7">
            <li>
                <u>Confidentiality</u>.
            </li>
        </ol>
        <p>
            Neither party nor any of their respective affiliates, if any, shall use or disclose to any person, directly
            or indirectly, any confidential information provided from either party to the other, except in performance
            of the obligations contained in in these Terms of Use or any Customer Agreement. However, nothing in this
            clause shall preclude either party or any of their respective affiliates, if any, from disclosing or using
            confidential information if:
        </p>
        <p>
            (i) the confidential information is available to the public or in the public domain at the time of such
            disclosure or use, without breach of these Terms of Use or any Customer Agreement;
        </p>
        <p>
            (ii) disclosure is required to be made by any law, regulation, governmental body or authority or by court
            order;
        </p>
        <p>
            (iii) disclosure is made in connection with any arbitration pursuant to these Terms of Use or any Customer
            Agreement; or
        </p>
        <p>
            (iv) disclosure is made to a court which is determining the rights of the parties under these Terms of Use
            or any Customer Agreement.
        </p>
        <p>
            Each party and their respective affiliates, if any, acknowledge and agree that the obligations under this
            clause are to remain in effect in perpetuity. Data produced pursuant to these Terms of Use or any Customer
            Agreement as part of the Services shall not be deemed to be confidential information hereunder.
        </p>
        <ol start="8">
            <li>
                <u>Term and Termination</u>
            </li>
        </ol>
        <p>
            These Terms of Use shall commence upon your first access to the Site and will continue for the period
            specified in your invoice or Customer Agreement (the &ldquo;<strong>Initial Term</strong>&rdquo;). Following
            the Initial Term, these Terms of Use will automatically renew for additional terms upon payment by you of
            the applicable Fees (each a &ldquo;
            <strong>Renewal Term</strong>&rdquo; and together with the &ldquo;Initial Term&rdquo;, the &ldquo;
            <strong>Term</strong>&rdquo;), unless otherwise terminated or cancelled as terminated herein or in a
            Customer Agreement.
        </p>
        <ol start="9">
            <li>
                <u>Representations and Warranties.</u>
            </li>
        </ol>
        <p>
            In addition to any representations and warranties that may be set out in a Customer Agreement, you represent
            and warrant to TPE as follows: (i) you have the authority to enter into and perform your obligations under
            these Terms of Use and any Customer Agreement; (ii) you have secured and will maintain any and all rights,
            consents and/or releases, including all Intellectual Property Rights, necessary to grant the licenses
            herein, including from any Authorized Users, independent contractors, providers and other third parties;
            (iii) in case you provide TPE with access to Video for the purposes of the overlaying such Video over the
            data and other content provided as part of the Services, such Video (regardless of source) was legally
            acquired whether by license or otherwise, you have the right to give TPE access to such Video for the
            purposes of use on the Site and the provision of the Services, and the provision to TPE of such Video shall
            not result in the breach by you of any agreement, contract or terms and conditions with any third party and
            will not result in the infringement of any third party rights by the you, TPE or the TPE Representatives;
            (iv) there are no existing or threatened claims or litigation which would materially adversely affect or
            materially adversely impair your ability to perform under these Terms of Use or any Customer Agreement; (v)
            you have no agreement with or obligations to any third party with respect to the rights herein granted which
            conflict or interfere with or adversely affect any of the provisions of these Terms of Use, any Customer
            Agreement or the use or enjoyment by TPE and the TPE Representatives of any of the rights herein granted;
            (vi) you have not sold, assigned, transferred or conveyed, and will not sell, assign, transfer, or convey,
            to any party any right, title, or interest in and to the rights herein granted or any part thereof, adverse
            to or in derogation of the rights herein granted to TPE; and (vii) all information provided to the Site or
            TPE or TPE Representatives is accurate and updated and you have the legal right to provide such information.
        </p>
        <p>
            TPE represents and warrants that it has the authority to enter into and perform its obligations under these
            Terms of Use and any Customer Agreement.
        </p>
        <p>
            EXCEPT FOR THE LIMITED WARRANTIES SET FORTH ABOVE, YOUR USE OF THE SERVICES AND THE SITE IS AT YOUR OWN RISK
            AND PROVIDED &ldquo;AS-IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;, WITHOUT ANY WARRANTIES, AND EACH PARTY
            DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND ANY ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.
        </p>
        <ol start="10">
            <li>
                <u>Indemnification</u>.
            </li>
        </ol>
        <p>
            You shall fully indemnify and hold TPE and the TPE Representatives harmless from any loss, liability, claim,
            costs, expenses or demand, including attorneys&rsquo; fees, made by any third party due to or arising out of
            (i) Video or other information to which you granted TPE access, the Services or the Site including any claim
            for intellectual property infringement, including trademark infringement, copyright infringement, passing
            off, unfair competition, patent infringement, breach of confidential information, or interference with a
            contract to which you are a party and (ii) any breach or failure by you to perform your obligations and
            responsibilities, including your obligations, representations and warranties set out in these Terms of Use
            and any Customer Agreement and you shall, at your sole expense, defend any such claim. This Section shall
            survive any termination or expiration of these Terms of Use.
        </p>
        <ol start="11">
            <li>
                <u>Limitation of Liability</u>.
            </li>
        </ol>
        <p>
            IN NO EVENT WILL TPE BE LIABLE TO YOU UNDER ANY THEORY OF TORT, CONTRACT, STRICT LIABILITY OR OTHER LEGAL OR
            EQUITABLE THEORY FOR ANY LOST PROFITS, LOSS OF SALE OR BUSINESS, LOSS OF AGREEMENTS OR CONTRACTS, LOSS OF OR
            DAMAGE TO GOODWILL, LOSS OF USE OR CORRUPTION OF SOFTWARE, DATE OR INFORMATION, LOST VIDEO CONTENT, OR
            STATISTICAL DATA, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, INDIRECT OR OTHER CONSEQUENTIAL DAMAGES, EACH OF
            WHICH IS HEREBY EXCLUDED BY AGREEMENT, REGARDLESS OF WHETHER OR NOT EITHER PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
            TPE&rsquo;S TOTAL CUMULATIVE LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY
            WILL NOT EXCEED 50% OF THE FEES PAID TO TPE BY YOU PURSUANT TO THESE TERMS OF USE DURING THE TWELVE MONTHS
            PRECEDING THE DATE ON WHICH THE LIABILITY ARISES.
        </p>
        <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE, ANY CUSTOMER
            AGREEMENT, YOUR ACCOUNT ON THE SITE OR YOUR USE OF THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER
            THE CAUSE OF ACTION ACCRUES. IF IT IS NOT, SUCH CAUSE OF ACTION OR CLAIM SHALL BE PERMANENTLY BARRED.
        </p>
        <ol start="12">
            <li>
                <u>Miscellaneous</u>.
            </li>
        </ol>
        <p>
            12.1 If for any reason a court of competent jurisdiction finds any provision of these Terms of Use invalid
            or unenforceable, that provision of these Terms of Use will be enforced to the maximum extent permissible
            and the other provisions of these Terms of Use will remain in full force and effect. The parties&rsquo;
            relationship is that of independent contractors. TPE may assign these Terms of Use or any of its rights or
            obligations hereunder (in whole or in part) without your consent. You may not assign these Terms of Use
            without the prior written consent of TPE.
        </p>
        <p>
            12.2 T hese Terms of Use shall be governed by and construed in accordance with the laws of the Province of
            Ontario. The Parties hereby submit to the jurisdiction of the courts of the Province of Ontario.
        </p>
        <p>
            12.3 A ny notices must be given in writing to the other party at the contact information indicated on an
            order(s), Customer Agreement or the Site, and shall be deemed given immediately upon personal delivery,
            three (3) days after deposit in the mail by certified or registered mail, one (1) day after overnight
            express courier; or upon confirmed transmission by fax or confirmed email receipt.
        </p>
        <p>
            12.4 These Terms of Use, the Customer Agreement and all referenced pages and invoices, if applicable,
            constitutes the entire agreement between the parties and supersedes all prior and contemporaneous
            agreements, proposals or representations, written or oral, concerning its subject matter. To the extent of
            any conflict or inconsistency between the provisions in these Terms of Use and any other documents or pages
            referenced in these Terms of Use, the following order of precedence will apply: (1) the terms of any written
            agreement such as the Customer Agreement, (2) these Terms of Use and (3) finally any other documents or
            pages referenced in these Terms of Use. Notwithstanding any language to the contrary therein, no terms or
            conditions stated in a purchase order, vendor onboarding process or web portal, or any other order
            documentation (excluding documents authored by TPE) will be incorporated into or form any part of these
            Terms and Conditions and all such terms or conditions will be null and void.
        </p>
        <p>
            12.5 Any delay in the performance of the Services or your ability to access the Site will not be considered
            a breach of these Terms of Use or any Customer Agreement if such delay is caused by acts of government or
            other civil or military authorities, labor dispute, shortage of materials, fire, earthquake, flood or any
            other event beyond the control of TPE.
        </p>
    </>
);
