/* eslint-disable eqeqeq */
import entries from './private/entries';
import gameEntries from './private/game-entries';
import gameEvents from './private/game-events';
import events from './private/events';
import gameShots from './private/game-shots';
import goaltendersShots from './private/goaltenders-shots';
import passing from './private/passing';
import penaltyKill from './private/penalty-kill';
import { pkLines } from './private/pk-lines';
import { possession } from './private/possession';
import { powerPlay } from './private/power-play';
import { ppLines } from './private/pp-lines';
import { shifts } from './private/shifts';
import { shots } from './private/shots';
import { playerShots } from './private/player-shots';
import { createSelector } from 'reselect';
import { processingState } from '../episode-utils';
import { allGamePlayersWithProfilesSelector } from '../../../../containers/widgets-stats/selectors/game-players';
import gamesSelector from '../../../../selectors/stats/games/games-selector';
import { singleEvent } from './private/single-events';
import individualShots from './private/individual-shots';
import individualEvents from './private/individual-events';
import singleShots from './private/single-shots';
import individualShifts from './private/individual-shifts';
import specialTeamsByTeamStatus from './private/special-teams-by-team-status';
import { SYNC, ANIMATION, VIDEO } from '../../utils/media-states';
import unfilteredEntries from './private/unfiltered-entries';
import draws from './private/draws';

const modules = [
    entries,
    gameEntries,
    gameEvents,
    gameShots,
    goaltendersShots,
    passing,
    penaltyKill,
    pkLines,
    possession,
    powerPlay,
    ppLines,
    shifts,
    shots,
    events,
    singleEvent,
    singleShots,
    playerShots,
    individualShifts,
    unfilteredEntries,
    specialTeamsByTeamStatus,
    individualShots,
    individualEvents,
    draws
];

export const EPISODE_TYPES = modules.reduce((a, module) => {
    return { ...a, ...module.types };
}, {});

/**
 * selector-module:{
 *     types - map of unique names
 *     selector - selector of source data for episodes
 *     mapper - (items[], itemType) => items[]  extracts data from selector result and marks playlist items by unique id
 *     getTimedGroup - (group, periodData, itemType) => items[]
 *     favoriteType - string | (type => string)
 * }
 */

const typeIdWithModuleListSelector = createSelector(
    (state, props) => props.groups,
    groups => {
        return Object.values(groups).map(typeId => ({
            typeId,
            module: modules.find(module => Object.values(module.types).includes(typeId))
        }));
    }
);

const getGroupsSelector = createSelector(
    typeIdWithModuleListSelector,
    typeIdWithModules => {
        return createSelector(
            typeIdWithModules.map(({ module }) => (state, props) => module?.selector(state, props)),
            (...values) =>
                values.map((value, i) => {
                    const { module, typeId } = typeIdWithModules[i];

                    if (!module || !typeId) {
                        throw Error('Do not find correct data for group selector.');
                    }

                    return module.mapper(value, typeId);
                })
        );
    }
);

const groupsSelector = createSelector(
    typeIdWithModuleListSelector,
    (state, props) => props.groupsData,
    (typeIdWithModules, groupsData) =>
        typeIdWithModules.map(({ typeId, module }, index) => {
            return { typeId, module, group: groupsData[index] };
        })
);

const groupsWithTimeSelector = createSelector(
    groupsSelector,
    state => state.widgetsStats.periods || processingState,
    state => state.widgetsStats.videoTimecodes || processingState,
    (groups, periods, videoTimecodes) => {
        if (periods.processing || videoTimecodes.processing) {
            return processingState;
        }

        return groups.map(({ typeId, module, group }) => {
            return {
                typeId, //excluding module from group Objects
                group: module.getTimedGroup(group, { periods, videoTimecodes }, typeId)
            };
        });
    }
);

const groupsWithPlayersSelector = createSelector(
    groupsWithTimeSelector,
    allGamePlayersWithProfilesSelector,
    (groups, players) => {
        if (groups.processing || players.processing) return processingState;

        return groups.map(({ typeId, group }) => {
            if (group.processing) {
                return processingState;
            }

            const groupWithProfile = group.data.map(episode => {
                const player =
                    players.data.find(p => p.gamePlayerId === episode.gamePlayerId) ||
                    players.data.find(p => p.playerId === episode.playerId);

                if (!player) return episode;

                const { jersey, profile } = player;

                return {
                    jersey,
                    profile,
                    ...episode
                };
            });

            return {
                typeId,
                group: groupWithProfile
            };
        });
    }
);

const filteredGroupsSelector = createSelector(
    groupsWithPlayersSelector,
    (state, props) => props.filter,
    (groups, filter) => {
        if (groups.processing) return processingState;

        if (typeof filter !== 'function') return groups;

        return groups.map(({ typeId, group, processing }) => {
            if (processing) return processingState;

            const filteredGroup = filter(group, typeId);

            return {
                typeId,
                group: filteredGroup
            };
        });
    }
);

const episodesComparator = (e1, e2) => {
    if (e1.gameId !== e2.gameId) {
        return e1.game.date > e2.game.date ? 1 : e1.game.date === e2.game.date ? 0 : -1;
    } else {
        return e1.realTime.time - e2.realTime.time;
    }
};

const checkMediaFiles = (storage, gameId, type) => {
    if (!storage || !storage.data || !storage.data[gameId]) return false;
    const files = storage.data[gameId];
    switch (type) {
        case VIDEO:
            return files.videoFile.fileExist;
        case ANIMATION:
            return files.animationFile.fileExist;
        case SYNC:
        default:
            return files.videoFile.fileExist && files.animationFile.fileExist;
    }
};

const sortedFilteredGroupsWithoutPlaylists = createSelector(
    filteredGroupsSelector,
    gamesSelector,
    (state, props) => props.groups,
    state => state.widgetsStats.storage,
    (state, props) => props.type,
    (groups, games, typeIdOrder, storage, type) => {
        if (groups.processing || games.processing) return processingState;

        return {
            data: typeIdOrder
                .filter(typeId => groups.find(g => g.typeId === typeId))
                .map(typeId => groups.find(g => g.typeId === typeId))
                .filter(g => g.group.length)
                .map(g => {
                    return {
                        typeId: g.typeId,
                        group: g.group
                            .map(g => ({
                                ...g,
                                hasMediaFile: checkMediaFiles(storage, g.gameId, type),
                                game: games.data.find(game => game.id === g.gameId)
                            }))
                            .sort(episodesComparator)
                    };
                })
        };
    }
);

const sortedFilteredGroups = createSelector(
    sortedFilteredGroupsWithoutPlaylists,
    state => state.icePlayer.playlistsMode,
    state => state.icePlayer.favorites,
    state => state?.statsFilter?.possessionZones,
    (defaultGroups, playlistMode, playlists, posessionZones) => {
        if (!playlistMode || !playlists) {
            return {
                data: posessionZones
                    ? defaultGroups?.data?.map(({ group, typeId }) => ({
                          typeId,
                          group: group.filter(el =>
                              posessionZones.some(zone => zone === el?.relationalZoneType?.toLowerCase())
                          )
                      })) || []
                    : defaultGroups?.data || []
            };
        }

        return {
            data: playlists.map(({ playlistId, items }) => ({
                typeId: playlistId,
                group: items
            }))
        };
    }
);

/*Episodes selector*/
export const mapStateToProps = (state, props) => {
    const groupsSelectors = getGroupsSelector(state, props);
    const groups = groupsSelectors(state, props);

    const episodes = sortedFilteredGroups(state, { ...props, groupsData: groups });

    return {
        episodes
    };
};
