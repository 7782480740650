/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import styles from './widget.module.css';
import styled from 'styled-components/macro';
import FormattedMessage from '../formatted-message/index';
import Refresh from '../refresh/refresh';
import { actions as tooltipsActions } from '../../containers/tooltip/module';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isMarketing } from '../../redux/modules/design';
import Loading from '../loading/loading';

const WidgetComment = styled.div`
    margin: 0 15px;

    font-weight: 600;
    font-size: 0.7em;
    letter-spacing: 0.5px;

    text-align: center;
    text-transform: uppercase;
`;

const LogoHeader = () => (
    <div className={styles.brandContainer}>
        <FormattedMessage className={styles.brand} id="brand" />
    </div>
);

const { showTooltip, hideTooltip } = tooltipsActions;
const TYPES = {
    Blue: true,
    Grey: true,
    Hidden: true
};

const getStyle = (color, className, obj) => {
    if (typeof color !== 'string') return obj[className];

    if (color.toLowerCase() === 'blue') {
        return obj[className];
    } else if (color in TYPES) {
        return obj[className + color];
    } else {
        return obj[className];
    }
};

function mapStateToProps(state) {
    return { marketing: isMarketing(state) };
}

class Widget extends Component {
    static instances = 0;

    static generateId() {
        return Widget.instances++;
    }

    state = {
        activeTab: this.props.defaultTab || 0,
        infoShown: false
    };

    constructor(props) {
        super(props);
        this.id = Widget.generateId();
    }

    selectTab(index) {
        const { onTabClick } = this.props;

        if (onTabClick) {
            onTabClick(index);
        }

        this.setState({
            activeTab: index
        });
    }

    renderTooltip() {
        return (
            <div ref={info => (this.info = info)} className={styles.tooltip}>
                {this.props.info.map((d, dickKey) => {
                    return d.map((text, i) => {
                        if (i) {
                            return (
                                <p key={`${dickKey}_${i}`} className={styles.infoParagraph}>
                                    {text}
                                </p>
                            );
                        } else {
                            return (
                                <span key={`${dickKey}_${i}`} className={styles.infoTitle}>
                                    {text}
                                </span>
                            );
                        }
                    });
                })}
            </div>
        );
    }

    componentWillUnmount() {
        this.props.hideTooltip(`#widget-${this.id}`);
        document.removeEventListener('click', this.outClick);
    }

    componentDidMount() {
        if (this.props.info) document.addEventListener('click', this.outClick);
    }

    outClick = event => {
        if (!this.state.infoShown) return;
        let element = event.target;

        while (element && element !== this.info && element !== this.infoButton) {
            element = element.parentNode;
        }

        if (!element) {
            this.props.hideTooltip(`#widget-${this.id}`);
            this.setState({
                infoShown: false
            });
        }
    };
    toggleInfo = event => {
        const { infoShown } = this.state;

        if (!infoShown) {
            this.props.showTooltip(`#widget-${this.id}`, event.target, this.renderTooltip());
        } else {
            this.props.hideTooltip(`#widget-${this.id}`);
        }

        this.setState({
            infoShown: !infoShown
        });
    };

    render() {
        const {
            title,
            children,
            type,
            className,
            detailsCb,
            height,
            width,
            tabs,
            tabIndicesToHidden = [],
            centerAlignmentTabs,
            customControl,
            customLeftControl,
            onClose,
            onRefresh,
            info,
            hideHeaderOnPrint,
            unbounded,
            style,
            marketing,
            comment,
            processing,
            withMargin,
            withBottomMargin,
            overflowVisible,
            withoutAnyHeader
        } = this.props;

        const { activeTab } = this.state;
        const blockTitle = title ? <div className={styles.titleHeader}>{title}</div> : null;
        const blockSeparator = title && detailsCb ? <div className={styles.headerItem}>|</div> : null;
        return (
            <div
                style={{
                    ...style,
                    ...(withMargin ? { marginTop: '10px' } : {}),
                    ...(withBottomMargin ? { marginBottom: '10px' } : {}),
                    ...(overflowVisible ? { overflow: 'visible' } : {})
                }}
                className={`${styles.frame} ${className ? className : ''} ${unbounded ? styles.unbounded : ''}`}
            >
                {withoutAnyHeader ? null : !marketing && (title || tabs || detailsCb) ? (
                    <div
                        className={cx(getStyle(type, 'header', styles), {
                            [styles.hideHeaderOnPrint]: hideHeaderOnPrint
                        })}
                    >
                        {blockTitle}
                        {blockSeparator}
                        {customLeftControl}
                        <div
                            className={cx(styles.headerItem, styles.details, {
                                [styles.centerAlign]: centerAlignmentTabs
                            })}
                        >
                            {detailsCb ? (
                                <div className={styles.underline} onClick={detailsCb}>
                                    <FormattedMessage id="widget.viewDetails" />
                                    <div className={styles.arrowRight} />
                                </div>
                            ) : null}
                            {!!tabs && (
                                <div className={styles.tabContainer}>
                                    {tabs.map((tab, tabKey) => (
                                        <div
                                            key={`tabKey_${tabKey}`}
                                            className={tabKey === activeTab ? styles.activeTab : styles.tabTitle}
                                            onClick={this.selectTab.bind(this, tabKey)}
                                        >
                                            {!tabIndicesToHidden[tabKey] && tab}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div
                            className={cx(styles.headerItem, styles.buttonsContainer, {
                                [styles.rightAlign]: !centerAlignmentTabs
                            })}
                        >
                            {onRefresh && (
                                <div className={styles.refreshContainer}>
                                    <Refresh onClick={onRefresh} />
                                </div>
                            )}
                            {info && (
                                <div
                                    className={styles.infoContainer}
                                    onClick={this.toggleInfo}
                                    ref={c => (this.infoButton = c)}
                                >
                                    ?
                                </div>
                            )}
                            {onClose && (
                                <div className={styles.closeButton} onClick={onClose}>
                                    <div className={styles.closeRight} />
                                    <div className={styles.closeLeft} />
                                </div>
                            )}
                        </div>
                        {customControl}
                    </div>
                ) : (
                    <div
                        className={cx(getStyle(type, 'header', styles), {
                            [styles.hideHeaderOnPrint]: hideHeaderOnPrint
                        })}
                    />
                )}
                {marketing && <LogoHeader />}
                <div
                    className={styles.content}
                    style={{
                        minHeight: height,
                        width: width
                    }}
                >
                    {processing && <Loading placeholder={this.props.placeholder} />}
                    {comment && <WidgetComment>{comment}</WidgetComment>}
                    {!processing && children}
                </div>
            </div>
        );
    }
}

Widget = connect(
    mapStateToProps,
    {
        showTooltip,
        hideTooltip
    }
)(Widget);
export { Widget as default };
